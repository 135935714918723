<template>
	<div class="service-box">
		<div class="service center-noflex" :style='{"background-image": "url("+backgrond_img+")"}' >
			<div class="title">{{cate_title}}</div>
			<div class="subtitle">{{cate_content}}</div>
			<div class="send" @click="fuwuruzhu()">申请入驻</div>
		</div>
		<div class="center servier-first">
			<div class="first-item">
				<img src="@/assets/test/test_Icon5.png" alt="" />
				<div class="first-item-title">研发服务</div>
				<div class="center">
					<div class="first-item-button" @click="GoLists({ id: '3' })">
						寻找创意研发机构
					</div>
				</div>
			</div>
			<div class="first-item">
				<img src="@/assets/test/test_Icon6.png" alt="" />
				<div class="first-item-title">投资服务</div>
				<div class="center">
					<div class="first-item-button" @click="GoLists({ id: '4' })">
						寻找创意研发机构
					</div>
				</div>
			</div>
			<div class="first-item">
				<img src="@/assets/test/test_Icon7.png" alt="" />
				<div class="first-item-title">代理服务</div>
				<div class="center">
					<div class="first-item-button" @click="GoLists({ id: '5' })">
						寻找创意研发机构
					</div>
				</div>
			</div>
			<div class="first-item">
				<img src="@/assets/test/test_Icon8.png" alt="" />
				<div class="first-item-title">律师服务</div>
				<div class="center">
					<div class="first-item-button" @click="GoLists({ id: '6' })">
						寻找创意研发机构
					</div>
				</div>
			</div>
		</div>
		<div class="service-institutions center-noflex">
			<stitle title="热门研发机构" subtitle="Popular R & D institutions"></stitle>
			<div class="institutions-list">
				<div v-for="(item, index) in institutionsList" :key="index" class="institutions-item" @click="GoDetail(item)">
					<img :src="item.image" alt="" style="width: 282px; height: 162px" />
					<div class="institutions-item-title">{{ item.title }}</div>
					<div class="institutions-item-content" v-html="item.remark"></div>
				</div>
			</div>
		</div>
		<div class="service-recommend">
			<stitle title="推荐服务" subtitle="Recommendation service"></stitle>
			<div class="center recommend-tabs">
				<div class="recommend-tabs-item" :class="{ footerSelect: footerSelect == 0 }" @click="Selectitem(0)">
					知产代理服务
				</div>
				<div class="recommend-tabs-item" :class="{ footerSelect: footerSelect == 1 }" @click="Selectitem(1)">
					知产法律服务
				</div>
				<div class="recommend-tabs-item" :class="{ footerSelect: footerSelect == 2 }" @click="Selectitem(2)">
					投融资服务
				</div>
			</div>
			<!-- <div class="center"> -->
			<el-carousel trigger="click" height="200px" :autoplay="false" indicator-position="none" ref="recommendCarousel">
				<el-carousel-item>
					<div class="center carousel-list">
						<div v-for="(item, index) in tabList" :key="index" class="carousel-item" @click="GoDetail(item)">
							<img :src="item.image" style="width: 140px; height: 140px; border-radius: 50%" alt="" />
							<div>{{ item.title }}</div>
						</div>
					</div>
				</el-carousel-item>
				<el-carousel-item>
					<div class="center carousel-list">
						<div v-for="(item, index) in tabList" :key="index" class="carousel-item" @click="GoDetail(item)">
							<img :src="item.image" style="width: 140px; height: 140px; border-radius: 50%" alt="" />
							<div>{{ item.title }}</div>
						</div>
					</div>
				</el-carousel-item>
				<el-carousel-item>
					<div class="center carousel-list">
						<div v-for="(item, index) in tabList" :key="index" class="carousel-item" @click="GoDetail(item)">
							<img :src="item.image" style="width: 140px; height: 140px; border-radius: 50%" alt="" />
							<div>{{ item.title }}</div>
						</div>
					</div>
				</el-carousel-item>
			</el-carousel>
			<!-- </div> -->
		</div>
	</div>
</template>

<script>
	import stitle from "components/title.vue";
	export default {
		components: {
			stitle,
		},
		async created() {
			const {
				data
			} = await this.$api({
				c: "index",
				a: "IP_service",
			});
			//console.log(data);
			this.institutionsList = data.arr.res;
			this.tabList = data.arr.re;
			this.tabList1 = data.arr.re;
			this.tabList2 = data.arr.re1;
			this.tabList3 = data.arr.re2;
			this.cate_title = data.arr.IP_banner.title;
			this.cate_content = data.arr.IP_banner.content;
			this.backgrond_img = data.arr.IP_banner.image;
			//console.log(this.cate_content);
		},

		// system.each.console.print("Hello World!")

		data() {
			return {
				tabList1: [],
				tabList2: [],
				tabList3: [],
				backgrond_img: require("@/assets/about/about_banner.png"),
				cate_title: 'IP活动',
				cate_content: '汇聚国内优质文化IP版权提供IP交易与授权',
				tabList: [{
						img: require("@/assets/test/test_icon10.png"),
						title: "机构名称",
						id: "1",
					},
					{
						img: require("@/assets/test/test_icon10.png"),
						title: "机构名称",
						id: "1",
					},
					{
						img: require("@/assets/test/test_icon10.png"),
						title: "机构名称",
						id: "1",
					},
					{
						img: require("@/assets/test/test_icon10.png"),
						title: "机构名称",
						id: "1",
					},
					{
						img: require("@/assets/test/test_icon10.png"),
						title: "机构名称",
						id: "1",
					},
					{
						img: require("@/assets/test/test_icon10.png"),
						title: "机构名称",
						id: "1",
					},
				],

				footerSelect: 0,
				institutionsList: [{
						img: require("@/assets/test/test_Icon9.png"),
						title: "机构名称",
						content: "简单介绍简单介绍简单介绍简单介绍简单介绍简 单介绍...",
					},
					{
						img: require("@/assets/test/test_Icon9.png"),
						title: "机构名称",
						content: "简单介绍简单介绍简单介绍简单介绍简单介绍简 单介绍...",
					},
					{
						img: require("@/assets/test/test_Icon9.png"),
						title: "机构名称",
						content: "简单介绍简单介绍简单介绍简单介绍简单介绍简 单介绍...",
					},
					{
						img: require("@/assets/test/test_Icon9.png"),
						title: "机构名称",
						content: "简单介绍简单介绍简单介绍简单介绍简单介绍简 单介绍...",
					},
					{
						img: require("@/assets/test/test_Icon9.png"),
						title: "机构名称",
						content: "简单介绍简单介绍简单介绍简单介绍简单介绍简 单介绍...",
					},
					{
						img: require("@/assets/test/test_Icon9.png"),
						title: "机构名称",
						content: "简单介绍简单介绍简单介绍简单介绍简单介绍简 单介绍...",
					},
					{
						img: require("@/assets/test/test_Icon9.png"),
						title: "机构名称",
						content: "简单介绍简单介绍简单介绍简单介绍简单介绍简 单介绍...",
					},
					{
						img: require("@/assets/test/test_Icon9.png"),
						title: "机构名称",
						content: "简单介绍简单介绍简单介绍简单介绍简单介绍简 单介绍...",
					},
				],
			};
		},
		methods: {
			Selectitem(e) {
				this.footerSelect = e;
				this.$refs.recommendCarousel.setActiveItem(e);
				if (e == 0) {
					this.tabList = this.tabList1;
				} else if (e == 1) {
					this.tabList = this.tabList2;
				} else if (e == 2) {
					this.tabList = this.tabList3;
				}
			},
			GoLists(e) {
				//this.$router.push("/index/servicedetali");
				//console.log(e);
				this.$router.push({
					path: "/index/IPstorage",
					query: {
						id: e.id,
					},
				});
			},
			GoDetail(e) {
				// var imgUrl = this.institutionsList[index].img;
				// var title = this.institutionsList[index].title;
				this.$router.push({
					path: "/index/servicedetali",
					query: {
						id: e.id,
					},
				});
			},
			fuwuruzhu() {
				this.$router.push("/index/service_add");
			},
		},
	};
</script>

<style lang="scss">
	.service-recommend {
		.el-carousel__arrow--left {
			display: none !important;
		}

		.el-carousel__arrow--right {
			display: none !important;
		}
	}
</style>


<style lang="scss" scoped>
	.service {
		height: 400px;
		color: #ffffff;
		// background: url("../../assets/service/service.png");
		background-size: 100% 100%;

		.title {
			font-weight: 900;
			font-size: 40px;
		}

		.subtitle {
			font-weight: 100;
			margin-top: 30px;
			margin-bottom: 15px;
		}

		.send {
			border-radius: 100px 100px 100px 0px;
			padding: 7px 16px;
			background: rgb(253, 71, 1);
		}
	}

	.servier-first {
		background: rgb(248, 248, 248);
		padding: 20px 0;
	}

	.service-institutions {
		background: #ffffff;
		padding-top: 30px;
	}

	.service-recommend {
		background: rgb(248, 248, 248);
		padding: 65px 0 89px;
	}

	.first-item {
		width: 283px;
		border-bottom: 1px solid #ffffff;
		background: #ffffff;
		margin-right: 11.5px;
		transition: all 0.4s;

		&:hover {
			border-bottom: 1px solid #0076fe;
		}

		img {
			margin-bottom: 11px;
		}

		.first-item-title {
			font-size: 24px;
			text-align: center;
			font-weight: 100;
		}

		.first-item-button {
			padding: 7.5px 13px;
			color: #0076fe;
			border: 1px solid #0076fe;
			margin: 20px 0px 18px;
		}
	}

	.institutions-list {
		width: 1200px;
		display: flex;
		// justify-content: space-between;
		flex-wrap: wrap;
		margin-left:-23px;
		// display: grid;
		// grid-template-columns: 282px 282px 282px 282px;
		// column-gap: 24px;
		// row-gap: 20px;
		padding: 46px 0px 49px;
		white-space: normal;

		.institutions-item {
			// width: 282px;
			width: 277px;
			margin-left:23px;
			margin-bottom: 20px;
			border-top: 1px solid #dfdfdf;
			padding: 8px 0px 16px;

			img {
				width: 100%;
				object-fit: cover;
				height: 160px;
			}

			.institutions-item-title {
				font-weight: 500;
				font-size: 16px;
				margin-bottom: 17px;
			}

			.institutions-item-content {
				color: #676767;
			}
		}
	}

	.recommend-tabs-item {
		border-bottom: 2px solid rgb(248, 248, 248);
		padding-bottom: 6px;
		color: #676767;
		margin-right: 40px;
	}

	.footerSelect {
		border-bottom: 2px solid #0076fe;
		color: #212121;
	}

	.recommend-tabs {
		margin-top: 24px;
		margin-bottom: 62px;
	}

	.carousel-item {
		img {
			// width: 83px;
			// height: 83px;
			margin-bottom: 10px;
		}

		margin-right: 20px;

		text-align: center;
	}
</style>
